<script setup lang="ts">
import { OffcanvasCart } from '@upa/design-system';
import defaultImg from '~/assets/default-img.png';

const { push } = useRouter();

const props = defineProps<{
  controller: ReturnType<typeof useModal>;
}>();

const { cartItems, totalPrice, isEmpty, cart } = useCart();
const { getFormattedPrice } = usePrice();

const { storeId } = useStore();

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

/**
 * Computed property to map line items with additional information.
 */
const mapLineItems = computed(() => {
  const lineItems: any = [];

  cartItems.value?.forEach((element: any) => {
    let lineItemProperties = element.payload;

    let characteristicsCollector: { icon: string; name: string }[] = [];
    let resortCollector = [];
    let resort = [];
    let productNumber = lineItemProperties.productNumber;

    let resortCollectorMain = [];
    let mainResort = ref(false);

    if (lineItemProperties.options.length > 0) {
      if (
        lineItemProperties.properties &&
        lineItemProperties.propertyIds &&
        lineItemProperties.propertyIds.length > 0
      ) {
        /**
         * Collecting resort information for the superTitle
         */
        for (let i = 0; i < lineItemProperties.propertyIds.length; i++) {
          if (
            lineItemProperties.properties[lineItemProperties.propertyIds[i]]
              ?.group?.translated.customFields &&
            lineItemProperties.properties[lineItemProperties.propertyIds[i]]
              ?.group?.translated.customFields
              .show_resort_subtitle_in_product_box &&
            lineItemProperties.properties[lineItemProperties.propertyIds[i]]
              ?.group?.translated.customFields
              .show_resort_subtitle_in_product_box == true
          ) {
            if (
              lineItemProperties.properties[lineItemProperties.propertyIds[i]]
                ?.group?.translated.customFields
                .group_use_in_frontend_checkbox_main_resort === true
            ) {
              mainResort.value = true;
              resortCollectorMain.push(
                lineItemProperties.properties[lineItemProperties.propertyIds[i]]
                  ?.translated?.name,
              );
            } else {
              resortCollector.push(
                lineItemProperties.properties[lineItemProperties.propertyIds[i]]
                  ?.translated?.name,
              );
            }
          }
        }

        resort = mainResort.value ? resortCollectorMain[0] : resortCollector[0];

        /**
         * Collecting characteristics from match of options and properties IDs for B2C and B2B - currently wanted: Language & Markt
         * custom field: group_use_in_frontend_checkbox_product_card needed for it from Extended Frontend Information
         * why? by default product has 2 options : markt, version and language, we want to display 2 of those: language and markt so they need to have the customField
         */
      }

      for (let j = 0; j < lineItemProperties.optionIds.length; j++) {
        const myObject = lineItemProperties.properties;

        if (
          lineItemProperties?.definedOptions &&
          lineItemProperties?.definedOptions[j]?.display
        ) {
          const newCharacteristic = {
            name: lineItemProperties?.definedOptions[j]?.option,
            icon: 'language',
          };
          characteristicsCollector.push(newCharacteristic);
        }

        for (const key in myObject) {
          if (myObject.hasOwnProperty(key)) {
            const property = myObject[key];
            if (
              Object.keys(property?.group?.translated?.customFields) &&
              Object.keys(property.group.translated.customFields).length != 0 &&
              property.group?.translated?.customFields
                .group_use_in_frontend_checkbox === false &&
              property.group?.translated?.customFields
                .group_use_in_frontend_checkbox_product_card == true
            ) {
              if (property.id == lineItemProperties.optionIds[j]) {
                const newCharacteristic = {
                  name: property?.translated?.name,
                  icon: property?.group?.translated?.customFields
                    ?.group_use_in_frontend_property_icon_name,
                };
                characteristicsCollector.push(newCharacteristic);
              }
            }
          }
        }
      }
    }

    // Product Number in Professional shop
    if (productNumber && storeId === 'professional-store') {
      const newCharacteristic = {
        icon: 'id',
        name: productNumber,
      };
      characteristicsCollector.push(newCharacteristic);
      resortCollector.push(productNumber);
    }

    let char = characteristicsCollector;

    /**
     * Adding formatted line item to the array
     */
    lineItems.push({
      ...element,
      productName: element.label,
      src: element.cover?.url || defaultImg,
      quantity: element.quantity,
      superTitle: resort != null ? resort : '',
      // price: element.price?.totalPrice ? getFormattedPrice(element.price?.totalPrice) : '',
      price: element.price?.totalPrice
        ? { totalPrice: element.price?.totalPrice }
        : '',
      hasQuantityBtn:
        storeId === 'stnet-store' ||
        storeId === 'professional-store' ||
        element.payload?.customFields
          ?.variantenartikel_quantity_selection_possible ||
        false,
      isOffcanvas: true,
      stock: element.deliveryInformation.stock,
      minStock:
        element.payload?.customFields?.st_stock_level_notification_min_stock ||
        0,
      maxQty:
        element.quantityInformation.maxPurchase >
        element.deliveryInformation.stock
          ? element.deliveryInformation.stock
          : element.quantityInformation.maxPurchase,
      minQty: element.quantityInformation.minPurchase,
      notB2B: storeId === 'consumer-store' || storeId === 'professional-store',
      isB2BShop: storeId === 'stnet-store',
      professionalShop: storeId === 'professional-store',
      issuuLink: '',
      inCart: true,
      characteristics: char,
    });
  });

  return lineItems;
});

/**
 * openOffcanvas is accessed here.
 * This is adapted here so that the OffcanvasCard opens
 * with toggleAddToCartProxy from SwProductCard.vue.vue.
 */
const isOpenCard = ref(props.controller.isOpen.value);
const openOffcanvas = inject('openOffcanvas') as Ref<boolean>;
const notificationOpen = inject('notificationOpen') as Ref<boolean>;

/**
 * Watching for changes in the Offcanvas state
 * and updating related variables
 */
watch(
  () => props.controller.isOpen.value,
  (newValue) => {
    isOpenCard.value = newValue;
    openOffcanvas.value = newValue;
    notificationOpen.value = false;
  },
);

watch(
  () => openOffcanvas.value,
  (newValue) => {
    openOffcanvas.value = newValue;
    isOpenCard.value = newValue;
  },
);

watch(notificationOpen, (newValue) => {
  notificationOpen.value = newValue;
});

/**
 * Function to close the Offcanvas
 */
const closeOffcanvasCard = () => {
  openOffcanvas.value = false;
  isOpenCard.value = false;
  props.controller.close();
};

let cart2: any = cart;
let errorMessage: any = [];

const errorText = async () => {
  Object.keys(cart2?.value?.errors).forEach((key) => {
    errorMessage = cart2?.value?.errors[key].message;
  });

  return errorMessage;
};

const ocErrors = computed(() => {
  if (cart2?.value?.errors) return cart2.value?.errors;
  else {
    return [];
  }
});

watch(cart2, async () => {
  errorText();
});

const disabledButton = ref(false);
</script>

<template>
  <OffcanvasCart
    :cart-item-list="mapLineItems"
    :open-notifaction="isEmpty ? true : notificationOpen"
    :notification="isEmpty ? 'info' : 'success'"
    :errors="ocErrors"
    :notifaction-message-error="errorMessage"
    :open-offcanvas="isOpenCard"
    :is-professional="storeId === 'professional-store'"
    :disabled-button="disabledButton"
    :total-price="
      totalPrice != 0
        ? getFormattedPrice(totalPrice)
        : $t('ProductCard.priceFree')
    "
    @go-to-checkout="
      push(formatLink(isEmpty ? '' : '/checkout')), (disabledButton = true)
    "
    @close-offcanvas-cart="closeOffcanvasCard"
    @close-notification="notificationOpen = false"
  >
    <template #checkoutCartItem>
      <CheckoutCartItem
        v-for="cartItem in mapLineItems"
        :key="cartItem.id"
        :cart-item="cartItem"
      />
    </template>
  </OffcanvasCart>
</template>
